<template>
  <div class="wrap message_board">
    <!-- <common-nav /> -->
    <div class="message_title_box wrap">
      <img src="@/assets/img/留言板.png" alt="">
    </div>
    <div class="message_box" @click="clickMessageBox">
      <div class="msg_box" v-for="(item, index) in messageList" :key="index">
        <div class="editor_name">
          <div>{{item.editor}}：<span style="color: gray; font-size: .1rem;">{{item.editTime | editTime}}</span></div>
          <div style="color: gray; font-size: .1rem;"># {{messageList.length - index}}</div>
        </div>
        <div class="content_box" v-html="item.content"></div>
        <div class="edit_time">
          <span class="reply" @click.stop="reply(item)">
            回复
          </span>
        </div>
        <div class="reply_box" v-for="(ritem, rindex) in showReplyList(item.reply)" :key="rindex">
          <div class="reply_title">
            <span>{{ritem.replier}}：</span><span style="color: gray; font-size: .1rem;">{{ritem.replyTime | editTime}}</span>
          </div>
          <div class="reply_content" v-html="ritem.replyContent"></div>
        </div>
      </div>
    </div>
    <div class="page_box">
      <div class="msg" @click.stop="msg">我要留言</div>
      分页栏
    </div>
    <div class="edit_box" :style="{bottom: showEdit ? '0' : '-2rem'}">
      <div class="edit wrap">
        <editor :actionType="actionType" :item="replyItem" @publish="getMessage" />
      </div>
    </div>
  </div>
</template>

<script>
  // import commonNav from '@/component/commonNav'
  import editor from '@/component/editor'
  import { allMessage } from '@/vue_api/message.js'
  export default {
    name: 'messageBoard',
    components: {
      // commonNav,
      editor,
    },
    data() {
      return {
        messageList: [],
        showEdit: 0,
        actionType: '',
        replyItem: {}
      }
    },
    filters: {
      editTime(val) {
        console.log(val.split(' ')[0])
        const hh = val.split(' ')[1].split(':')[0]
        const mm = val.split(' ')[1].split(':')[1]
        if (new Date(val.split(' ')[0].split('-').join('/')).getTime() === new Date(new Date().toLocaleDateString()).getTime()) {
          return '今天 ' + hh + ':' + mm
        } else if (val.split('-')[0] == new Date().getFullYear()) {
          return parseInt(val.split(' ')[0].slice(5).split('-')[0]) + '月' + parseInt(val.split(' ')[0].slice(5).split('-')[1]) + '日 ' + hh + ':' + mm
        } else {
          return val.split(' ')[0] + ' ' + hh + ':' + mm
        }
      }
    },
    mounted() {
      this.getMessage()
    },
    methods: {
      msg() {
        this.actionType = 'msg'
        this.showEdit = 1
        this.$bus.$emit('editFocus', true)
      },
      reply(val) {
        this.actionType = 'reply'
        this.showEdit = 1
        this.replyItem = val
        this.$bus.$emit('editFocus', true)
        console.log(val)
      },
      getMessage() {
        console.log('富文本点了publish----')
        allMessage().then(res => {
          console.log('-----', res.data.messageList)
          if (res && res.data && res.data.messageList) {
            this.messageList = res.data.messageList
          }
        })
      },
      clickMessageBox() {
        this.$bus.$emit('closeEmoji', false)
        this.showEdit = 0
      },
      showReplyList(val) {
        if(!val) {return null}
        let replyList = val.split('!~#~!')
        replyList = replyList.splice(1, replyList.length - 1)
        return replyList.map(item => {
          if(item.trim()) {
            console.log(item)
            item = JSON.parse(item)
          }
          return item
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import './index.scss'
</style>